/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import { DistributionChart, TheLayout, Theseo } from "../components"
import { FaShoppingBasket } from 'react-icons/fa'

const SearchListings = () => {
  return (
    <TheLayout>
      <Theseo title="Search Listings Page" />
      <div>
        
        <DistributionChart />

        <Link to="/" sx={{ variant: "links.navlink" }}>
          Go back to the homepage
        </Link>
      </div>
    </TheLayout>
  )
}

export default SearchListings
